import React from 'react';
import Layout from '../components/layout';

const headerText = 'ACCESSIBILITY';

const Accessibility = () => {
  return (
    <Layout headerText={headerText} heroFont="has-text-white" heroBg="fields">
      <section className="section">
        <div className="content">
          <div className="container">
            <div>
              <h3 className="is-size-3 is-uppercase">
                Accessibility Statement
              </h3>
              <p>
                iDECIDE is committed to making our website and resources
                available to everyone.
              </p>
              <p>
                We have developed our content based on WCAG and ARIA
                specifications. We monitor our website regularly to maintain
                this, but if you find any problems, please contact us.
                Additionally, If you have any questions about our accessibility
                features, are in need of assistance, or require these materials
                in an alternative format (e.g., larger text, braille), please
                contact our team at{' '}
                <a href="mailto:idecide@mgh.harvard.edu">
                  iDECIDE@mgh.harvard.edu
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Accessibility;
